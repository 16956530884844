/* ==========================================================================
   NEWS ENTRIES
   ========================================================================== */
td.news-date {

}

td.news-item {
    display: inline-block;
    vertical-align: top;
    max-width: 500px;
}

div.bibliography-container {
    counter-reset: bibitem;
    ol {
        margin:0 0 1.5em;
        padding:0;
    }

    ol>li {
        margin:0;
        padding:0 0 0 2em;
        text-indent:-2em;
        list-style-type:none;
        counter-increment:bibitem;
    }
    ol li::before {
        display:inline-block;
        width:1.5em;
        padding-right:0.5em;
        font-weight:bold;
        text-align:right;
        content:counter(bibitem) ".";
    }

    ol>li>div {
        margin:0;
        padding:0 0 0 2em;
        text-indent:-2em;
        list-style-type:none;
    }
}

a.hidden-link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
}
