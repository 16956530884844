
///////////// My additional styles
img.sidebar_icon
{
  display:inline-block;
  width:1em;
  margin-left: 0.1em;
  margin-right: 0.16em;
  height:1.1em;
  line-height:1;
  position:relative;
  top:-0.1em;
  vertical-align:middle;
}


.googlescholar::before
{
    // content: url(/assets/images/icon-google-scholar.png);
    background-image: url(/assets/images/icon-google-scholar.png);
    background-size: 1em 1.1em;
    display: inline-block;
    width: 1em;
    height: 1.1em;
    content:"";
    margin-left: 0.1em;
    margin-right: 0.16em;
    top:0.2em;
    position:relative;
}

li.location
{
  white-space: normal;

  span {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    @include breakpoint($large) {
      width: auto;
    }
    max-width: 80%;
  }

  a {
    display: inline;
    margin-bottom: 05px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.hide-unless-large
{
  display: none;
  @include breakpoint($large) {
    display: block;
  }
}


/*
   Wide Pages
   ========================================================================== */

.wider {
  .page {
    @include breakpoint($large) {
      width: 100%;
      padding-left: 0;
    }

    @include breakpoint($x-large) {
      width: 100%;
      padding-left: 0;
    }
  }

  .page__related {
    @include breakpoint($large) {
      width: 100%;
      padding-left: 0;
    }

    @include breakpoint($x-large) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.wider .sidebar {
  margin-bottom: 1em;

  @include breakpoint($large) {
    position: initial;
    top: initial;
    left: initial;
    width: initial;
    margin-left: initial;
    padding-right: initial;
    z-index: initial;

    &.sticky {
      float: none;
    }
  }

  @include breakpoint($x-large) {
    width: initial;
    margin-left: initial;
  }
}


.site__intro {
  a {
    color: inherit;
    text-decoration: none;
  }
}


.author__avatar_name {
    float: left;
    text-align: center;
}

.arxiv {
  content: url('/assets/images/arxiv.svg');
  height: 1em;
  width: auto;
}
